import React from "react"
import { Flex } from "theme-ui"

import { TileImage } from "../Page/ContentModules/Tile/elements"
import { NewsletterInput } from "../new-ui/newsletter-signup"

const SubscribeTileSection = ({ tileSection }) => {
  return (
    <>
      {tileSection?.tiles && (
        <Flex
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: ["column", "row"],
            gap: "2px",
          }}
        >
          {tileSection.tiles?.[0]?.backgroundImage && (
            <Flex sx={{ flex: 1, width: ["100%", "initial"] }}>
              <TileImage
                image={tileSection.tiles[0].backgroundImage.gatsbyImageData}
                alt=""
              />
            </Flex>
          )}
          <Flex
            sx={{
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              marginTop: ["32px", "0px"],
              paddingX: ["0px", "0px"],
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                width: "100%",
                justifyContent: ["flex-start", "center"],
                minHeight: "200px",
                maxWidth: "450px",
                paddingInline: "16px",
              }}
            >
              <NewsletterInput
                showDescription={true}
                collab={
                  tileSection.title === "subscribe-tile-section" ? true : false
                }
                jaq={
                  tileSection.title === "subscribe-jacquemus-tile-section"
                    ? true
                    : false
                }
                title={tileSection.displayTitle || ""}
                subtitle={tileSection.displaySubtitle || ""}
                source={
                  tileSection.displayTitle === "The Archive is now closed."
                    ? "archive_closed"
                    : "archive_subscribe_tile_section"
                }
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default SubscribeTileSection
